//
// Progress.scss
//

.pricing-box {
    // min-height: 800px;
  .price-title {
    color: $muted;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: $font-weight-semibold;
  }
  .pricing-label {
    position: absolute;
    display: block;
    top: 15px;
    right: 14px;
    h5 {
      width: 60px;
      height: 60px;
      line-height: 60px;
      background: $primary;
      border-radius: 50%;
    }
  }
  .pricing-features {
    p {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 12px;
    }
  }
}

.price {
  position: relative;
  color: $white;
  font-size: 18px;
  height: 55px;
  line-height: 55px;
  width: 150px;
  margin: 0 auto;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 8px;
    background: $primary;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 8px;
    transform: rotate(-6deg);
    background: rgba($primary, 0.58);
  }
}
